<template>
  <div>
    <a class="cursor-pointer" @click="open = !open">
      <slot name="button"></slot>
    </a>

    <!-- to close when clicked on space around it-->
    <button class="fixed inset-0 h-full w-full cursor-default focus:outline-none bg-transparent" v-if="open" @click="open = false" tabindex="-1"></button>

    <!--dropdown menu-->
    <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-750 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div class="absolute shadow-lg border w-48 rounded p-2 py-1 text-sm mt-2 z-10 bg-white" :class="placement === 'right' ? 'right-0' : 'left-0'" v-if="open">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        open: false,
      };
    },
    props: {
      placement: {
        type: String,
        default: "right",
        validator: (value) => ["right", "left"].indexOf(value) !== -1,
      },
    },
    mounted() {
      const onEscape = (e) => {
        if (e.key === "Esc" || e.key === "Escape") {
          this.open = false;
        }
      };

      document.addEventListener("keydown", onEscape);

      this.$once("hook:beforeDestroy", () => {
        document.removeEventListener("keydown", onEscape);
      });
    },
  };
</script>
